<template>
  <div class="modal edit" style="height: calc(100vh + 20px); top: -20px; background: #161616">
    <div class="modal__block">
      <div class="list__info more" style="display:flex; align-items: flex-start">
        <div>{{ $t('more_about_id') }}: {{ item.id }}</div>
      </div>
      <div class="btn__group more" style="margin-top: 15px">
        <button
          @click="openEdit(item)"
        >
          {{ $t('edit_design') }}
        </button>
        <button
          @click="openEditUrl"
        >
          {{$t('edit_campaign_url')}}
        </button>
        <button
          v-if="item.clo_info.status !== 'DEPRECATED' && item.clo_info.status !== null"
          @click="openEditClo"
        >
          {{ $t('edit_clo') }}
        </button>
        <button @click="cancel">
          {{ $t('back') }}
        </button>
      </div>
<!--      <div class="btn__group" style="margin-top: 5px">-->
<!--        <button>-->
<!--          Push Menu-->
<!--        </button>-->
<!--        <button>-->
<!--          cloaka-->
<!--        </button>-->
<!--      </div>-->
    </div>
  </div>
  <modal-edit-campaign
    v-if="isOpenEditUrl"
    :item="item"
    @close="isOpenEditUrl = !isOpenEditUrl"
  />
  <modal-edit-clo
    v-if="isOpenEditClo"
    :item="item"
    :geoList="geoList"
    @close="isOpenEditClo = !isOpenEditClo"
  />
</template>
<script>

import ModalEditCampaign from "@/components/EditCampaignUrl.vue";
import ModalEditClo from "@/components/EditClo.vue";
export default {
  name: 'modal-more',
  components: {
    ModalEditCampaign,
    ModalEditClo
  },
  props: ['item'],
  data() {
    return {
      isOpenEditClo: false,
      isOpenEditUrl: false,
      geoList: [],
    }
  },
  methods: {
    cancel() {
      this.$emit("close");
    },
    fetchGeo() {
      const vm = this;
      console.log('this item', this.item);
      this.axios.get(`keitaro_geos.txt`)
        .then((response) => {
          console.log('responsse', response);
          vm.geoList = response.data;
        })
        .catch((error) => {
          console.error('error', error);
        })
    },
    openEdit(item) {
      window.open(`https://pwa-builder.com/?keitaro_id=${item.landing_id}&builder_mode=edit&alias=${item.domains[0].alias}`, '_blank');
    },
    openEditUrl() {
      this.isOpenEditUrl = true;
    },
    openEditClo() {
      this.isOpenEditClo = true;
    }
  },
  created() {
    this.fetchGeo()
  }
}
</script>